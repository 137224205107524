.has-same-height .card {
  height: 320px;
  overflow: hidden;
}

.card-content h3 {
  text-align: center;
}

.table-profile td,
th {
  border: none !important;
}

.table-profile td:first-of-type {
  font-weight: bold;
}

.skills-content .media {
  margin: 0 !important;
  padding: 4px !important;
  border: 0 !important;
}

.skills-content .progress {
  height: 4px !important;
}

.custom-tags {
  text-align: center !important;
  display: block;
}

.portfolio-container .card {
  margin-bottom: 2em;
}

.section-heading {
  text-align: center;
  margin-top: 1em;
  margin-bottom: 6em;
}
.iconeWhatsapp {
  font-size: 50px; /* Tamanho do ícone */
  color: #fff;     /* Cor inicial do ícone */
  transition: color 0.3s ease; /* Transição suave da cor */
}

.iconeWhatsapp:hover {
  color: #25d366; /* Cor do ícone quando o mouse está sobre ele */
}

.iconeInstagram {
  font-size: 50px; /* Tamanho do ícone */
  color: #fff;     /* Cor inicial do ícone */
  transition: color 0.3s ease; /* Transição suave da cor */
}

.iconeInstagram:hover {
  color: #dd2a7b ; /* Cor do ícone quando o mouse está sobre ele */
}